import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import service_img from "../images/chris-liverani-9cd8qOgeNIY-unsplash.jpg";
import SEO from "../components/seo";
import ContentLayout from "../components/content-layout";

const Service = ({ title, url, children }) => {
  return (
    <div className="w-full sm:w-1/2 p-2 border-t">
      <Link
        to={url}
        className="text-2xl text-red-700 hover:text-red-600 hover:underline"
      >
        {title}
      </Link>
      <div
        className="markdown-body excerpt"
        dangerouslySetInnerHTML={{ __html: children }}
      ></div>
    </div>
  );
};

const Services = ({ data }) => {
  const services = data.allMarkdownRemark.edges;
  return (
    <Layout headerClass="bg-white relative">
      <SEO title="Services" />
      <ContentLayout title={`Services`} image={service_img} color="md:text-read-700">
        <div className="flex flex-wrap md:mt-28 justify-evenly border-b">
          <p className="my-2 md:my-4 text-2xl font-bold">Our service offerings, designed so that you can mix and match. Contact us for more details.</p>
          {services.map(({ node }) => (
            <Service
              title={node.frontmatter.title}
              url={node.frontmatter.path}
              key={node.frontmatter.path}
            >
              {node.excerpt}
            </Service>
          ))}
        </div>
      </ContentLayout>
    </Layout>
  );
};

export const query = graphql`
  query ServicesQuery {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/services/" }
        frontmatter: { draft: { eq: false } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
          }
          excerpt(pruneLength: 600, format: HTML)
        }
      }
    }
  }
`;

export default Services;
